<template>
   <section class="mb-10 why-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs12 sm10 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center mb-6">

                                     <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Benefits to your Business</h3>
                                     <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Benefits <br /> to your <br /> Business</h3>


                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row >
                
                                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" v-for="(section, index) in sectionInfo" :key="index" class="content-holder">

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                            <v-col cols="12" lg="2" md="2" sm="12" xs="12" align="center" justify="center">
                                                <div class="content-number mb-3"> {{ content.number }} </div>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider"></v-divider>
                                                <v-divider :color="`${zamuBlack}`" class="icon-divider"></v-divider>
                                            </v-col>
                                            <v-col cols="12" lg="10" md="10" sm="12" xs="12" class="px-2">
                                               <div class="content-text mx-2" v-html="content.text">
                                               </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Benefits',
    mixins: [colorMixin, coreMixin],
     computed: {
        sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'yoga.png',
                    imgClass: 'yoga',
                    content: [
                        {
                            number: '01',
                            header : 'Stable API',
                            text: `
                            <p>
                            This service has been known to trigger behavior change and desired responses. For instance, call center staff can be notified of an unexpected downtime on a certain service and advice on how to respond to customer queries on the same. This ensures coherent and consistent messaging throughout the period. 

                            <p>
                            `
                        },
                    ]
                },
                 {
                    section: 2,
                    reverse: true,
                    image: 'secure-payment.png',
                    imgClass: 'secure-payment',
                    content: [
                        {
                            number: '02',
                            header : 'Secure',
                            text: `
                           <p> 
                           Integrate our easy to use API and begin to enjoy easy and cost-effective means of reaching your customers. You can use the service to create general awareness of products, gather customer feedback through surveys as well as upsell to your existing clients by informing them of a new solution or a value add to what they already subscribed to.
                           </p>`
                        },
                     
                    ]
                },
                {
                    section: 3,
                    reverse: true,
                    image: 'yoga.png',
                    imgClass: 'yoga',
                    content: [
                             {
                            number: '03',
                            header : 'Fast and Easy',
                            text: `
                            <p> 
                            In addition, you can enhance your customer satisfaction and retention by sending simple messages of goodwill in alignment with national and international days of celebration to your clients.
                             </p>
                            `
                        },
                    ]
                },
                {
                    section: 4,
                    reverse: false,
                    image: 'pay.png',
                    imgClass: 'pay',
                    content: [
                        {
                            number: '04',
                            header : '24/7 Support',
                            text: `
                             <p> 
                              Enhance your marketing and sales promotions by sending trigger action messages via USSD or SMS and maximize your outreach potential.
                            </p>
                            `
                        },
                   
                    ]
                }
            ];
            return data;
        },
     },

}
</script>

<style scoped>
.why-holder {
    background-image: url('https://pilongtermbackups.s3.amazonaws.com/Zamupay/images/rabbitWatermark.png') !important;
    background-position: center bottom  !important;
    background-size: 42%;

}
 .content-number {
     color: #d29f2a;
    font-size: 28px;
    font-weight: 700;
    line-height: 29.55px;
 }
 .content-title {
     color: #43237d;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
 }
 .content-text {
    color: #727272;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
 }
 .content-image-holder {
    position: relative;

 }
</style>

